.footer {
  height: 400px;
  text-align: center;
  padding: 20px;
  background-color: rgb(223, 223, 223);
}

.bot {
  bottom: 0;
  right: 0;
  position: fixed;
  width: 350px;
}

.rpm-logo {
  color: #fff;
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  font-size: 24px;
}

body {
  background: #345db7;
}

.patient-monitoring {
  background: #fff;
}

.pagination-row {
  background: #fff;
}

.nav-item.hide-left-nav-item {
  display: none;
}

.patient-monitoring-details .col-form-label {
  padding-top: 0;
  padding-bottom: 0;
}

body>iframe {
  display: none;
}

.active_bookmark {
  color: #fff;
  background-color: #345db7;
  border-color: #345db7;
}

#data-table-zero td {
  border-collapse: collapse;
  text-align: center !important;
}

.patient_card_readmore {
  font-size: 10px;
  text-decoration: underline;
  margin-top: 2px;
  margin-left: 0;
  display: inline-block !important;
}
.patient_card_readmore a {
  color: #345db7 !important;
}
.nav-item .nav-link:hover {
  cursor: pointer;
}

.patient-monitoring-details .card-body {
  padding-bottom: 0;
}

.patient-monitoring .specialist td {
  text-align: left !important;
}

.align-right {
  float: right;
}

.physician-calendar .fc .fc-toolbar-title {
  font-size: 1em;
}

.physician-calendar .fc .fc-button .fc-icon,
.physician-calendar .fc .fc-button {
  font-size: 0.8em;
  text-transform: capitalize;
}

.physician-calendar .fc-view {
  margin-top: 0;
}

.physician-calendar .fc table {
  font-size: 0.95em;
}

.physician-calendar .fc .fc-col-header-cell-cushion,
.physician-calendar .fc .fc-daygrid-day-number {
  color: #37474f;
}

.app {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
  height: 100vh;
  background-color: #0a1230;
}

input[type="submit"] {
  margin-top: 10px;
  cursor: pointer;
  font-size: 15px;
  background: #345db7;
  border: 1px solid #345db7;
  color: #fff;
  padding: 10px 20px;
}

input[type="submit"]:hover {
  background: #345db7;
}

.button-container {
  display: flex;
  justify-content: center;
}

.login-form {
  background-color: white;
  padding: 4rem;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  width: 13cm;
}
#loginpage{
  background:url("cssimage/loginbg1.jpg") no-repeat left top;

  
}

.list-container {
  display: flex;
}

.error {
  color: red;
  font-size: 12px;
}

.title {
  font-size: 25px;
  margin-bottom: 20px;
}

// .input-container {
//   display: flex;
//   flex-direction: column;
//   gap: 8px;
//   margin: 10px;
// }
.login-success {
  color: rgb(44, 234, 44);
  font-size: 15px;
}

.table.dataTable[class*=table-] thead th,
.table thead th {
  // background: #ecf0f5;
  text-align: center !important;
}

table.table-bordered.dataTable tbody th,
table.table-bordered.dataTable tbody td {
  // border-bottom-width: 0;
  text-align: center !important;
}

.pcoded-header.header-blue {
  position: fixed;
  // background: #496989 !important;
}

.custom-breadcrumb {
  position: fixed;
  z-index: 1029;
  margin-top: -50px;
}

.custom-breadcrumb ul {
  margin: 0 0 0 0;
  padding: 0;
}

.custom-breadcrumb ul:before,
.custom-breadcrumb ul:after {
  content: '';
  display: table;
}

.custom-breadcrumb ul li {
  list-style: none;
  margin: 0;
  padding: 0 10px 0 0;
  float: left;
}

.custom-breadcrumb ul li,
.custom-breadcrumb ul li a {
  color: #fff;
}

.custom-breadcrumb ul li.active {
  font-weight: bold;
}

/*Global table style*/

.table td,
.table th {
  padding: 0.75rem 0.75rem !important;
}

.btn.btn-icon {
  width: 35px !important;
  height: 35px !important;
}

.card-header {
  padding: 1rem 1rem;
}

// .lightRed {
//   background-color: #ff8080 !important
// }

// .white {
  // color: #ffffff;
  // font-weight: bold;
// }

.red {
  background-color: rgb(252, 115, 115);
}

.yellow {
  background-color: rgb(250, 222, 132);
}

.noti-head.bg-dark {
  height: 70px !important;
}

// Emoji styles

.emoji .item {
  width: 45px;
  height: 30px;
  display: inline-block;
  justify-content: center;
  align-items: center;
  user-select: none;
}

.emoji .radio {
  display: none;
}

.emoji .radio~span {
  font-size: 2rem;
  filter: grayscale(50);
  // cursor: pointer;
  transition: 0.3s;
}

.emoji .radio:checked~span {
  filter: grayscale(0);
  // font-size: 4rem;
}

// .emoji .radio~span:hover {
//   filter: grayscale(0);
// }

#PatExpPiechart{
  height: 460px;
}

// notification dropdown styles

.noti-head .nav-pills .nav-link.active, .noti-head .nav-pills .show > .nav-link {
  background-color: transparent;
}

.noti-head .dropdown .notification ul li {
  padding: 10px 15px 15px;
}

// .pcoded-header .dropdown .notification .noti-head h6 {
//   font-weight: 100;
// }

.pcoded-header .dropdown .notification .noti-head .nav-item .active h6 {
  font-weight: bold;
}

.pcoded-header .dropdown .notification .noti-head {
  padding: 6px 20px 0;
}

.cardheightmood{
  height: 552px;
}

//econnect
.chat-online {
  color: #34ce57
}

.chat-offline {
  color: #e4606d
}

.flex-shrink-1{
  // background-color: rgb(219, 242, 255);
  background-color: #53cfbfd5;
}
.chat-message-right .flex-shrink-1{color: #fff;}
.chat-messages {
  display: flex;
  flex-direction: column;
  // max-height: 800px;
  // overflow-y: scroll;
  // min-height: 215px;
  // max-height: 44vh;
  // max-height: 48vh;
  margin-bottom: 30px;
}

.chat-message-left,
.chat-message-right {
  display: flex;
  flex-shrink: 0
}

.chat-message-left {
  margin-right: auto
}

.chat-message-right {
  flex-direction: row-reverse;
  margin-left: auto
}
.py-3 {
  padding-top: 1rem!important;
  padding-bottom: 1rem!important;
}
.px-4 {
  padding-right: 1.5rem!important;
  padding-left: 1.5rem!important;
}
.flex-grow-0 {
  flex-grow: 0!important;
}
.border-top {
  border-top: 1px solid #dee2e6!important;
}
//2
.reply {
  height: 60px;
  width: 100%;
  background-color: #f5f1ee;
  padding: 10px 5px 10px 5px !important;
  margin: 0 !important;
  z-index: 1000;
}

.reply-emojis {
  padding: 5px !important;
}

.reply-emojis i {
  text-align: center;
  padding: 5px 5px 5px 5px !important;
  color: #dbd13d;
  cursor: pointer;
  border: black;
}

.reply-emojis1 {
  padding: 5px !important;
}
.reply-emojis1 i {
  text-align: center;
  padding: 5px 5px 5px 5px !important;
  color: #345db7;
  cursor: pointer;
}

.reply-recording {
  padding: 5px !important;
}

.reply-recording i {
  text-align: center;
  padding: 5px !important;
  color: #345db7;
  cursor: pointer;
}

.reply-send {
  padding: 5px !important;
}

.reply-send i {
  text-align: center;
  padding: 5px !important;
  color: #345db7;
  cursor: pointer;
}

.reply-main {
  padding: 2px 5px !important;
}

.reply-main textarea {
  width: 100%;
  resize: none;
  overflow: hidden;
  padding: 5px !important;
  outline: none;
  border: none;
  text-indent: 5px;
  box-shadow: none;
  height: 100%;
  font-size: 16px;
  background-color: rgb(216, 211, 211);
}

.reply-main textarea:focus {
  outline: none;
  border: none;
  text-indent: 5px;
  box-shadow: none;
}

#prescriptionbuton{
  background-color: lawngreen;
   height: 50px;
   width:150px;
   font-size: 20px;
}

/*Dashboard css*/
.pb45{padding-bottom: 45px !important;}
.text-c-orange{color: #6d9b3a;}

.tabs-dash-nav .nav.dash-tab-pills {
  // position: fixed;
  margin-left: -20px;
  margin-top: -35px;
  background-color: #fff;
  background-clip: border-box;
  border: 0px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  padding: 6px 22px 1px 7px;
  z-index: 3;
  min-width: 77.7vw;
}
/*Dashboard css*/

/* add-prescription css*/
.add-prescription{
  border:0;
  background-color:#345db7;
  color:#fff;
  margin-bottom:20px;
  padding:12px 15px;
}

.prescription-table-section{
  box-shadow: 0 2px 10px -1px rgb(69 90 100 / 30%);
  margin-bottom: 30px;
  transition: box-shadow 0.2s ease-in-out;
}

.tab-content h6 {
    font-size: 0.825rem;
}
// .chat-messages.p-4{
//   text-align: center;
//   // width: 100%;
//   // height: 100%;
// }
// .rounded-circle.mr-1{
//   align-content: center;
//   margin-left: 43%;
// }
.call-avatar{
  height: 50px;
  width: 50px;
  text-align: center;
}
#voicecall{
  width: 100%;
  height: 100%;
}

//Voicecall
.voice-call-avatar {
	flex-direction: column;
	display: flex;
	align-items: center;
	justify-content: center;
	flex: 2;
}
.voice-call-avatar .call-avatar {
	margin: 15px;
	width: 250px;
	height: 250px;
	border-radius: 100%;
	border: 1px solid rgba(0, 0, 0, 0.1);
	padding: 3px;
	background-color: #fff;
}
.call-duration {
	display: inline-block;
	font-size: 20px;
	//margin-top: 4px;
	position: absolute;
	left: 0;
  bottom: 10px;
}
.voice-call-avatar .call-timing-count {
	padding: 5px;
}
.voice-call-avatar .username {
	font-size: 18px;
	text-transform: uppercase;
}
.call-icons {
	text-align: center;
	position: relative;
}
.call-icons .call-items {
	border-radius: 5px;
	padding: 0;
	margin: 0;
	list-style: none;
	display: inline-block;
}
.call-icons .call-items .call-item {
	display: inline-block;
	text-align: center;
	margin-right: 5px;
}
.call-icons .call-items .call-item:last-child {
	margin-right: 0;
}
.call-icons .call-items .call-item a {
	color: #777;
	border: 1px solid #ddd;
	width: 50px;
	height: 50px;
	line-height: 50px;
	border-radius: 50px;
	display: inline-block;
	font-size: 20px;
}
.call-icons .call-items .call-item a i {
	width: 18px;
	height: 18px;
}
.user-video {
	bottom: 0;
	left: 0;
	overflow: auto;
	position: absolute;
	right: 0;
	top: 70px;
	z-index: 10;
}
.user-video img {
	// // width: 700px;
  // width: 310px;
	// max-width: 100%;
	// // height: 450px;
	// max-height: 100%;
	display: block;
	margin: 0 auto;
  max-width: 240px;
}
.user-video video {
	width: auto;
	max-width: 100%;
	height: auto;
	max-height: 100%;
	display: block;
	margin: 0 auto;
}
.my-video {
	position: absolute;
	z-index: 99;
	bottom: 20px;
	right: 10px;
}
.my-video ul {
	margin: 0;
	padding: 0;
	list-style: none;
}
.my-video ul li {
	float: left;
	width: 120px;
	margin-right: 10px;
}
.my-video ul li img {
	border: 3px solid #fff;
	border-radius: 6px;
  width: 200px;
}
.end-call {
	position: absolute;
	top: 9px;
	right: 7px;
  color: #fff;
}
.end-call input{
  margin-right: 20px;
  cursor: pointer;
}
.end-call a {
	color: #fff;
	border-radius: 50px;
	display: inline-block;
	background-color: #f06060;
	padding: 8px 25px;
	text-transform: uppercase;
}
.call-users {
	position: absolute;
	z-index: 99;
	bottom: 20px;
	right: 20px;
}
.call-users ul {
	margin: 0;
	padding: 0;
	list-style: none;
}
.call-users ul li {
	float: left;
	width: 80px;
	margin-left: 10px;
}
.call-users ul li img {
	border-radius: 6px;
	padding: 2px;
	background-color: #fff;
	border: 1px solid rgba(0, 0, 0, 0.1);
}
.call-mute {
	width: 80px;
	height: 80px;
	background-color: rgba(0, 0, 0, 0.5);
	position: absolute;
	display: inline-block;
	text-align: center;
	line-height: 80px;
	border-radius: 6px;
	font-size: 30px;
	color: #fff;
	display: none;
	top: 0;
	border: 3px solid transparent;
}
.call-users ul li a:hover .call-mute {
	display: block;
}
.call-details {
	margin: 10px 0 0px;
	display: flex;
}
.call-info {
	margin-left: 10px;
	width: 100%;
}
.call-user-details,
.call-timing {
	display: block;
	text-overflow: ellipsis;
	overflow: hidden;
	white-space: nowrap;
}
.call-description {
	white-space: nowrap;
	vertical-align: bottom;
}
.call-timing {
	color: #a0a0a2;
	display: flex;
	font-size: 14px;
	margin-top: 1px;
	overflow: hidden;
	white-space: pre;
}
.rounded-circle{
  height: 40px;
  width: 40px;
}
.chat-footer{
  position: absolute;bottom:15px; left: 15px;width: 100%;
}

  .fixed-header {
    background-color: #fff;
    border-bottom: 1px solid #eaeaea;
    padding: 2px 2px;
  }

//chat sidebar
.notification-list .list-group-item {
	padding: 15px;
}

/*-----------------
	40. Chat Sidebar
-----------------------*/

.chat-sidebar {
	background-color: #fff;
	border-left: 1px solid #eaeaea;
	width: 300px;
}
.chat-sidebar .chat-contents {
	background-color: #fff;
}
.chat-sidebar .chat-left .chat-content {
	border: 0;
	padding: 0;
	border-radius: 0 !important;
	background-color: transparent;
	max-width: 100%;
}
.chat-sidebar .chat-left .chat-body {
	margin-left: 50px;
	padding-right: 0;
}
.chat-sidebar .chat-date {
	background-color: #fff;
}
.chat-sidebar .chats {
	padding: 15px 15px 30px;
}
.chat-sidebar .avatar {
	width: 30px;
	height: 30px;
	line-height: 30px;
}
.chat-sidebar .chat.chat-left {
	margin-bottom: 20px;
}
.chat-sidebar .chat.chat-left:last-child {
	margin-bottom: 0;
}
.chat-sidebar .chat-left .chat-time {
	color: #888;
	display: inline-block;
	font-size: 11px;
}
.chat-sidebar .chat-content > p {
	font-size: 13px;
	margin-bottom: 0;
}
.chat-sidebar.opened {
	margin-right: 0;
}
/*-----------------
	37. Video Call
-----------------------*/

.content-full {
	height: 100%;
	position: relative;
	width: 100%;
}
.video-window .fixed-header {
	padding: 0;
	border: 0;
}
.video-window .fixed-header .nav > li > a {
	padding: 19px 15px;
}
#patexperience{
  margin-left: 15px;
  width: 100%;
  margin-right: 15px;
}



.presc-section table.dataTable thead .sorting{
  background-image: none !important;
  background: #ecf0f5;
}

.presc-section table > tbody > tr > td {
  border: 0;
  border-left: 1px solid #e2e5e8;
}

.feather.icon-alert-circle{
	color: #ffba57;
}
.feather.icon-calendar{
	color: #6c757d;
}
.feather.icon-book{
	color: #9ccc65;
}
.add-prescr-btn{
  background-color: #345db7;
  color:#fff;
  margin:0 auto;
  border:0;
  width: 150px;
  padding: 8px 0;
  display: block;
}

.add-pres-popupbtn{
  border: 0;
  width: 100px;
  padding: 8px 0;
  display: block;
  margin: 0 auto;
}

/*header styles*/
.user-status label{
	font-family: "Open Sans", sans-serif;
  font-size: 12px;
}

.user-status .switch input[type=checkbox] + .cr:after{
  background:#fff;
}

.user-status .switch input[type=checkbox] + .cr:after{
  // background: #f7f7f7;
  height: 17px;
  width: 19px;
}

.user-status .switch input[type=checkbox] + .cr:before {
  height: 18px;
  width: 33px;
  border-radius: 30px;
}

.user-status .switch input[type=checkbox]:checked + .cr:after {
  left: 14px;
}

.user-status .switch.switch-primary input[type=checkbox]:checked + .cr:before {
  // background: #cccccc;
  background: #00FF00;
}
.user-status .switch input[type=checkbox] + .cr{
  border: 0;
}
.user-status .switch input[type=checkbox]:checked + .cr{
  border: 0;
}
.user-status .switch input[type=checkbox] + .cr:before{
  background: #cccccc;
}

.cont {
  width: 250px;
  height: 250px;
  margin-top: 50px;
  margin-left: 150px;
  background-color: violet;
}
.button {
  border-radius: 8px;
  font-size: 20px;
  background-color: red;
  color: white;
  margin-left: 70px;
  margin-top: 100px;
}
.cont2 {
  width: 250px;
  height: 250px;
  margin-top: 50px;
  margin-left: 150px;
  background-color: yellow;
}

.user-profile-header {
  padding: 7px;
  border-radius: 50%;
  // color: #fff;
  // background-color: #00acc1;
  border: 2px solid #fff;
  margin-top: 0;
  margin-left: -25px;
}

.user-profile-text{
  color: #fff;
  line-height: 1.2;
  position: absolute;
  left: 44px;
  top: 11px;
  text-align: left;
}
.user-profile-text span{
  font-size:10px;
  font-style: italic;
  display: block;
}
.pcoded-header .navbar-nav > li:last-child {
  padding-right: 25px;
  width: 190px;
  position: relative;
  margin-left: 15px;
}
.textarea{
  width:100% ;/*466px*/
  height: 189px;
}
.reffer-patient a {
	color: #fff;
	border-radius: 50px;
	display: inline-block;
	background-color: #3670ee;
	padding: 8px 25px;
  text-align: center;
	// text-transform: uppercase;
}
.refer-button{
  margin-left: 42%;
}


/*Antd css*/

.custom-breadcrumb .feather,
.navbar-nav .feather{
  font-size:18px;
}

.emergency-tab{
  padding:0 10px 0 20px;
}

.custom-breadcrumb ul li, .custom-breadcrumb ul li a {
  font-size: 15px;
}

.user-status{
  margin-bottom: 0;
}

.user-status label{
  line-height: 1;
  text-align: center;
  margin: 12px 0 0 0;
  color: #fff;
}

.user-status .switch input[type=checkbox] + .cr{
  top: 0px;
  left: -7px;
}
.user-status .switch input[type=checkbox] + .cr:after{
  /* background-color:#666;*/
  background-color:#fff
  }
  .user-status .switch.switch-primary input[type=checkbox]:checked + .cr:after{
  /* background-color:#008450*/
  background-color:#fff
  }

.header-logo{
    margin-left: 30%;/*360px*/
}

.mobile-menu{
  left:20px;
}

.sendbutton{
  font-size: 18px !important;
  margin-left: 5px !important;
} 

.btn.btn-danger.btn-sm.align-right {
  color: white;
}
// prescription popup table
.presc-modal-dialog {
  max-width: 640px;
}
.popup-pres-table .add, .popup-pres-table .delete {
  padding: 0 10px;
  background: #fff;
}
.popup-pres-table input {
  padding: 0;
  height: auto;
  border: 1px dotted;
}
table.popup-pres-table > thead{
  position:fixed;
  z-index: 1;
  margin-top: -20px;
}
table.popup-pres-table > tbody{
  padding-top: 0;
  position: absolute;
  top: 48px;
  background: #fff;
  width: 96.3%;
}
table.popup-pres-table td, table.popup-pres-tablee th {
  border: 1px solid #e2e5e8;
}
table.popup-pres-table thead > tr > th:nth-child(1){
  width:150px;
}
table.popup-pres-table thead > tr > td:nth-child(1){
  width:160px;
}
table.popup-pres-table thead > tr > th:nth-child(2){
  width:150px;
}
table.popup-pres-table thead > tr > td:nth-child(2){
  width:160px;
}
table.popup-pres-table thead > tr > th:nth-child(3){
  width:132px;
}
table.popup-pres-table thead > tr > td:nth-child(3){
  width:132px;
}
table.popup-pres-table thead > tr > th:nth-child(4){
  width:152px;
}
table.popup-pres-table thead > tr > td:nth-child(4){
  width:132px;
}
table.popup-pres-table thead > tr > th:nth-child(5){
  width:182px;
}
table.popup-pres-table tbody > tr > td:nth-child(5){
  width:182px;
}
.popup-pres-table .add:hover,
.popup-pres-table .delete:hover{
  color: #9ccc65;
}
.presc-pop-body{
  overflow-y: scroll;
  min-height: 220px;
  background-color: #fff;
}
.ant-table-tbody > tr > td.freq_s{width: 50px !important;}
.tabletd{text-align: center;display: block;}

/* Alert Accordion styles start*/
.alert-accordion .col h4 {
  font-size: 1.2rem;
}
.alert-accordion .card .card-block, .alert-accordion .card .card-body {
  padding: 10px 10px;
}
.alert-accordion .card .card-header h5{
  display:block;
  padding: 1rem 1rem;
  margin: 0;
}
.alert-accordion .card .card-header h5 a{
  display:block;
}
.alert-accordion .highcharts-title{
  font-weight: bold;
  font-size:15px !important;
  font-family: "Open Sans", sans-serif
}
.alert-accordion .highcharts-root text {
  font-weight: bold;
  font-size:12px !important;
  font-family: "Open Sans", sans-serif
}
.alert-accordion .card .card-header h5 a .feather.icon-chevron-down{
 float:right; 
}
.alert-accordion .card-header {
  padding: 0 0;
}
.alert-accordion .accordion > .card{
  margin-bottom: 15px;
  max-height: 75vh;
  overflow-y: scroll;
}
/* Alert Accordion styles end*/

/* header stylesheet start*/
.nav-pills li > span{    
	text-decoration: underline;
  font-size: 13px;
	color: #fff;
  cursor:pointer;
}
.pcoded-header .dropdown .notification .noti-head h6 {
  font-weight: bold;
}
// .pcoded-header .dropdown .notification .noti-body{
//   overflow-y: scroll;
// }
ul.noti-body li.notification{
  cursor:pointer;
}
.pcoded-header .dropdown .notification .noti-body li .n-time {
  margin-top: 10px;
}
/* header stylesheet end*/

.fas-fa-paperclip:hover{
  cursor: pointer;
  // color: aqua !important;
}
.attach-popup{
  width: 200px;
  // vertical-align: middle;
  margin-left: 50%;
  margin-top: 20%;
  // margin-bottom: 10%;
}
.fa-image {
color: #345db7;
cursor: pointer;
}
.fa-file-video{
  color: #345db7;
  cursor: pointer;
}
.fa-file-pdf{
  color: #345db7;
  cursor: pointer;
}
.attach-item{
  font-weight: bold;
  padding-left: 2px;
  padding: 1px;
  // cursor: pointer;
}

/*custom media queries*/

@media (min-width: 576px){
.presc-add .modal-dialog {
    max-width: 870px;
}
}

.popup-pres-table tr label{
  display: block;
  width: 110px;
  height: 20px;
}
.popup-pres-table tr label span{
  float: left;
  width: 75px;
}
.popup-pres-table tr label input{
  float: right;
  width: 22px;
  margin: 4px 0 0 0;
}

/*attach option css*/
.attach-item label span{
	font-size:12px;
}
.attach-item label{
	cursor: pointer;
}

.fa-times{
  cursor: pointer;
  margin-right: 5px;
}
// .crossicon{
// //  margin-left: 50%;;
// text-align: right;
// }
// .expandicon{
//   text-align: right;
// }
// .pdfmodelheader{
//   height: 10px;
//   // margin-bottom: 5%;
//   // margin-top: 5px;
//   // text-align: center;
// }
// .pdfmodeltitle{
//   margin-top: 0.5%;
// }


/*sticky patient card start*/
.custom-breadcrumb.patcard-breadcrumb {
  margin-top: -130px;
}
.bodycard{
    margin-top: 80px;
}
.patcard.sticky-pcard{
  position: fixed;
  z-index: 999;
  min-width: 79.7vw;
  top: 50px;
}

.pat-desc-title{
  margin: 35px 0 0 -46px;
  display: block;
  font-weight: bold;
  font-size: 14px;
}

.pat-desc-text{
  margin:0 0 0 0;
  display: inline-block;
 
}
.pat-desc-text1, .pat-desc-text2, .pat-desc-text3{
  margin:0 30px 0 0;
  display: inline-block;
 
}
.pat-top-info span:nth-child(1),
.pat-top-info span:nth-child(2){
  width: 38%;
}
.pat-top-info span:nth-child(3){
  width: 20%;
}
.pat-desc-text.age{
  margin:0 4px 0 0;
}
.bodycard.sticky-pagecard{
  margin-top:220px
}
.pat-top-info{
  margin: 0 auto;
  width:80%;
  padding:0;
}
.custom-breadcrumb.sticky-breadcrumb{
  margin-top: -270px;
}

@media only screen and (max-width: 1280px){
  .patcard.sticky-pcard{
    min-width: 79.7vw;
  }
}

.user-card .change-profile .profile-dp {
  width: 90px;
  height: 90px;
}

.pat-menu-tabs {
  float: right;
}

.custom-card-search{
  margin:0;
}
.custom-card-search .card-body{
  padding:5px;
}
/*sticky patient card end*/

/*tab styling*/
.sticky-pcard .user-profile-f1 .profile-tabs .nav-item .nav-link:after{
  background: transparent;
}

.sticky-pcard .user-profile-f1 .profile-tabs .nav-link.active{
  color:#fff !important;
  background-color: #345db7;
  font-weight: bold;
}

.bodycard .nav-pills .nav-link.active{
  background: transparent;
  border-bottom: 2px solid #345db7;
  color: #345db7 !important;
  border-radius: 0;
}

.sticky-pcard .user-profile-f1 .profile-tabs {
  border-bottom: 0;
  margin-top: 13px;
}

.bodycard .nav-pills .nav-link {
  padding: 0.2rem 0.8rem;
}

.bodycard .custom-card-search .card-body {
  padding: 5px 0 0;
}

.bodycard .card .card-header h5 {
  font-size: 0.9rem;
  padding: 7px 0 0 7px;
}

.bodycard .card .card-header h5 span{
  font-weight:100;
  font-size: 12px;
 }

.bodycard .card-header {
  padding: 0.5rem 0.5rem;
}
.tabs-pat-nav .nav.pat-tab-pills{
    position: fixed;
    margin-left: 2px;
    margin-top: -20px;
    background-color: #fff;
    background-clip: border-box;
    border: 0px solid rgba(0, 0, 0, 0.125);
    border-radius: 0.25rem;
    padding: 17px 22px 17px 0;
    z-index: 3;
    min-width: 76.5vw;
}
.card .card-body.tabs-pat-nav {
  padding: 20px 20px;
}
.tabs-pat-nav > .tab-content{
  margin-top:40px;
}

/*mood styling*/
.tbody{
  line-height: 0.9;
}
//vitals popup//
.BPRange{
  text-align: center;
  margin-left: 35%;
  font-weight: bold;
}
.BPto{
  // text-align: center;
  margin-top: 3%;
  align-content: center;
}
.ant-tag{width: 95px;}

.form-group {
	margin-bottom: 1.25rem;
  margin-bottom: 20px;
}

input[type="date"]::before {
	color: #999999;
	content: attr(placeholder);
}
input[type="date"] {
	color: #ffffff;
}
input[type="date"]:focus,
input[type="date"]:valid {
	color: #666666;
  content: "mm/dd/yyyy" !important;
}
input[type="date"]:focus::before,
input[type="date"]:valid::before {
	content: "" !important;
}
/*menu hover styles*/
// .sticky-pcard .user-profile-f1 .profile-tabs .nav-link:hover{
//   color: #fff !important;
//   background-color: #345db7;
// }

// .bodycard .nav-pills .nav-link:hover,
// .card-header-tabs .nav-link:hover {
//   background: transparent;
//   // border-bottom: 2px solid #345db7;
//   color: #000 !important;
//   border-radius: 0;
// }

.form-control {
  cursor: pointer;
}

// .nav-link.text-reset:hover{
//   color: #fff !important;
//   background-color: #345db7;
// } 	 

.pDetails{
  height:308px;
}
.definerange{
  padding-top: 20px;
}

.patient-monitoring-details.card{
    min-height: 330px;
}
#ebody{
  // min-height: 330px;
  height: 330px;
}
#eholebody{
  margin-top: 80px;
  width: 98.5%;
}
#econnect{width: 98.5%;}
// .img-radius{} 
// .img-fluid{} 
.wid-160{ width: 160px;}
.wid-140{ width: 140px;}
.widsvg{ width: 33px;}
.widpng{ width: 95px;}
/*patient card picture section*/

.user-profile-card-left{
  box-shadow: 0 0px 10px 2px rgb(69 90 100 / 10%);
  transition: box-shadow 0.2s ease-in-out;
  margin-left: -4px;
  margin-right: 0;
  padding: 0 32px 0 56px;
}
.user-profile-pic{
  display: inline-block;
  box-shadow: none !important;
  width:80px
}
.user-profile-title{
  display: inline-block;
  font-weight: bold;
  font-size: 14px;
  // margin: 25px 0 0 12px;
  margin: 0px 0 0 12px;
}
.user-profile-title a{
  text-decoration: underline;
}
.range-row .form-control{
  margin:0;
  padding:0;
  height:15px;
  text-align: center;
}

.range-row.row{
  margin-left:0 !important;
  text-align: right;
}
.end-call .custom-control {
  display: inline-block;
  margin-right: 10px;
}
.powered{color: #333; font-size: 10px !important; text-align: center;}
.transcribe {
  color: #373a3c;
  font-size: 14px !important;
  text-align: center;
  position: relative;
  left: -9px;
  top: 0px;
  text-transform: capitalize;
  font-weight: bold
}

.chat-bt-bar{
  background-color: #fff;
  z-index: 20;
}
// custom media query for echat window

@media (max-width: 1280px){
  .user-profile-card-left{
    flex: 0 0 25%;
    max-width: 25%;
	padding: 0 0 0 30px;
}
.user-profile-title {
    font-size: 12px;
    margin: 35px 0 0 12px;
}
}
@media (min-width: 1280px){
  // #eholebody, #econnect{
  // background-color:red;
  // }
    .chat-messages{
      min-height: 63vh;
    }
    // #ebody {
    //   min-height: 330px;
    // }
    .patcard.sticky-pcard {
      min-width: 79.7vw;
    }
    #eholebody {
      width: 100%;
      margin-bottom: 0;
    }
    #econnect {
      width: 100%;
    }
    #ebody {
      height: 295px;
      overflow-y: scroll;
    }
    #econnect_ebody{ 
      height: 295px;
      overflow-y: scroll;
    }
  }
  
  
  @media (min-width: 1366px){
  //   #eholebody, #econnect{
  // background-color:yellow;
  // }
  .chat-messages{
    min-height: 63vh;
  }
  #ebody {
    height: 345px;
    overflow-y: scroll;
  }
  #econnect_ebody{ 
    height: 345px;
    overflow-y: scroll;
  }
  .patcard.sticky-pcard {
    min-width: 80.8vw;
  }
  #eholebody {
    width: 100%;
    margin-bottom: 0px;
  }
  #econnect {
    width: 100%;
  }
  }
  
  
  @media (min-width: 1920px){
  // #eholebody, #econnect{
  //   background-color:blue;
  // }
  .chat-messages{
    min-height: 63vh;
  }
  #ebody {
    height: 330px;
  }
  .patcard.sticky-pcard {
    min-width: 86.5vw;
  }
  #ebody {
    height: 70vh;/*645px*/
    overflow-y: scroll;
  }
  #econnect_ebody{ 
    height: 70vh;/*645px*/
    overflow-y: scroll;
  }
  }

  .ant-tag-yellow {
    color: #d4b106;
    background: #feffe6;
    border-color: #d4b106 !important;
}
.range_value{ text-align: center; font-weight: bold;}
.text-c-blue {
  color: #345db7 !important;
}

.settableicons{
  width: 85px;
   margin-top: 6px;
  float: right;}

  .downloadlistbutton{
    margin-top: 1.2rem;
}

.uploadbutton{
  margin-top: 0.8rem;
}

.temp{
  color:#020305;
  font-weight: bold;
}

.downloadbutton{
  background: #345db7;
  color: #fff;
  border: 1px solid #345db7;
  border-radius: 3px;
}

.upbutton{
  color: #fff;
    background-color: #43877f;
    border-color: #43877f;
    border: 1px solid transparent;
    padding: 0.625rem 2.1875rem;
    font-size: 0.875rem;
    line-height: 0.5;
    border-radius: 2px;
    //font-size: medium;
    margin-left: 5px;
 
}
.btn-primary.disabled, .btn-primary:disabled {
  color: #fff;
  background-color: #608a85 !important;
  border-color: #608a85 !important;
}
.btn-primary{
  background-color: #345db7 !important;
  color: #fff !important;
}

.upbutton2{
  color: #fff;
    background-color: #20c997;
    border-color: #20c997;
    border: 1px solid transparent;
    padding: 0.625rem 2.1875rem;
    font-size: 0.875rem;
    line-height: 0.5;
    border-radius: 2px;
    //font-size: medium;
    margin-left: 5px;
}

.uplbuttons{
  border: solid;
  padding: 0.525rem 2.1875rem;
  border-right-width: 4px;
  border-radius: 30px;
  padding: .5em;
}
.shorttxt{
  white-space: nowrap; 
  width: 76px; 
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 0px;}
// .shorttxt.a {
//   white-space: nowrap; 
//   width: 100px; 
//   overflow: hidden;
//   text-overflow: ellipsis;
//   border: 1px solid #f00;
// }

// .shorttxt.a:hover {
//   overflow: visible;
// }
.tabs-dash-nav .nav.dash-tab-pills{
  margin-left: 0px;
    margin-top: -10px;

    background-color: #fff;
    background-clip: border-box;
    // border-bottom: 1px solid #496989;
    // border-radius: 0em;
    // padding: 0px 22px 1px 7px;
    z-index: 3;
    // min-width: 77.7vw;
}
.nav-tabs .nav-link {
  // border: 1px solid #999;
  border-bottom: 1px solid #345db7;
  // border-radius: 0.2rem;
  background: no-repeat center bottom, center 100%;
  background-size: 0 100%, 100% 100%;
  margin-right: 0px;
  transition: background 0.3s ease-out;
  background-image: linear-gradient(to top, #345db7 2px, rgba(255, 255, 255, 0) 2px);
  position: relative;
  top: 1px;
  font-size: 15px;
  color:#345db7;
  font-weight: bold;
}
// a{
//   color: #496989 !important;
// }

.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link{
  color: #fff;
    background-color: #345db7;
    border-color: #dee2e6 #dee2e6 #fff;
    font-weight: bold;
}
.card .card-block, .card .card-body {
  padding: 10px 20px 10px 20px;
}
.red-row {
  // color: red;
  background: #def0ff;
}

 .green-row{
  // color: red;
  background: #d4e9ff;
}
.mismatch-row{
  background:#ffd9dd;
  // width: ;
}
.matched-row{
  background: #c5e2ba;
}

.blue-row{
  // color: red;
  background: #cbdff0;
}

.dropdown-select {
    text-transform: none;
    border: 1px solid #345db7;
    height: 30px;
    width: 150px;
    border-radius: 5px;
}

.btn-sm, .btn-group-sm > .btn {
  border-radius: 5px;
}

.btn-success {
  color: #fff !important;
  background-color: #345db7 !important;
  border-color: #345db7 !important;
  // box-shadow: 0 5px 10px 0 rgba(0,0,0,0.2), 0 5px 15px 0 rgba(0,0,0,0.19);
}
.btn-success:hover{
background-color: #85b4e3 !important;
}

.btn-success:disabled{
  background-color: #96a4bb !important;
}

.pcoded-navbar a {
  color: #96a4bb;
  font-size: 16px !important;
}
.card{
  margin-bottom: 20px !important;
  margin-top: 8px !important;
  // background-color: #ccced1;
}
.btn-success:disabled{
  background-color: #c4d4ed;
}

.ant-table-thead > tr > th {
  position: relative;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 500;
  text-align: left;
  background: #e7edf9 !important;
  border-bottom: 1px solid #f0f0f0;
  transition: background 0.3s ;
}
.tabletabs{
  color: #345db7 !important;
}
.subheader{
  color: #020305;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-left: 15px;
  font-size: 18px;
}
.btn-outline-primary{
  color: #345db7;
  border-color: #345db7;
}
.btn-outline-primary:hover{
  color: #a3c7ea !important;  
  background-color:#345db7;
  background: #345db7;
}

.btn-sm{
  color: #fff !important;
  background-color: #345db7 !important;
  border-color: #345db7 !important;
}
.btn-sm:hover{
  background-color: #a3c7ea !important;
}

//loader css

.loaderCard{
  margin-left: 47%;
  width : 150px;
  height: 150px;
}
 
.loader-wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  margin-top: 10px !important;
  margin-left: 50px !important;
  /* background-color: #0b0b0b; */
  // display: grid;
  place-items: center;
  // margin-left: 70px;
}
 
.loader {
  border: 4px solid #e3e3e3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 3s linear infinite;
  // margin-left: 50px;
}
.Compchart{
  margin: -.5em auto; 
  text-align:center; 
  width: 90%; height: 390px
}

.nav-link.text-reset.active{
  background-color: #345db7 !important;
}
// #345db7
.view-calc-details {
  color: #fff !important;
  background-color: #345db7 !important;
  border-color: #345db7 !important;
  border-radius: 5px;
  // box-shadow: 0 5px 10px 0 rgba(0,0,0,0.2), 0 5px 15px 0 rgba(0,0,0,0.19);
}
.view-calc-details:hover{
background-color: #85b4e3 !important;
}