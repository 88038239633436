.highcharts-title{
  font-weight: 600 !important;
  color: #37474f !important;
    font-size: 0.9rem !important;
}
.nav-pills .nav-link.active, .nav-pills .show > .nav-link{
  color: #fff !important;
}
/*Antd css*/
/*! CSS Used from: Embedded */
.anticon{display:inline-block;color:inherit;font-style:normal;line-height:0;text-align:center;text-transform:none;vertical-align:-0.125em;text-rendering:optimizeLegibility;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;}
.anticon > *{line-height:1;}
.anticon svg{display:inline-block;}
.anticon::before{display:none;}
/*! CSS Used from: https://dct-sponsor-provider-journey.cloud.pcftest.com/_next/static/css/4d52c327e02b358a.css */
a:not([href]):not([class]),a:not([href]):not([class]):hover{color:inherit;text-decoration:none;}
th{text-align:-webkit-match-parent;}
@media print{
*,:after,:before{text-shadow:none!important;box-shadow:none!important;}
a:not(.btn){text-decoration:underline;}
tr{page-break-inside:avoid;}
}
ul{margin-bottom:1rem;}
a{color:#007bff;}
a:hover{color:#0056b3;text-decoration:underline;}
a:not([href]):not([tabindex]),a:not([href]):not([tabindex]):focus,a:not([href]):not([tabindex]):hover{color:inherit;text-decoration:none;}
a:not([href]):not([tabindex]):focus{outline:0;}
svg{overflow:hidden;vertical-align:middle;}
th{text-align:inherit;}
.table-striped tbody tr:nth-of-type(odd){background-color:rgba(0,0,0,.05);}
.table-responsive{display:block;width:100%;overflow-x:auto;-webkit-overflow-scrolling:touch;-ms-overflow-style:-ms-autohiding-scrollbar;}
@media print{
*,:after,:before{text-shadow:none!important;box-shadow:none!important;}
a:not(.btn){text-decoration:underline;}
thead{display:table-header-group;}
tr{page-break-inside:avoid;}
}
[class*=ant-] input::-ms-clear,[class*=ant-] input::-ms-reveal,[class*=ant-]::-ms-clear,[class^=ant-] input::-ms-clear,[class^=ant-] input::-ms-reveal,[class^=ant-]::-ms-clear{display:none;}
input::-ms-clear,input::-ms-reveal{display:none;}
*,:after,:before{box-sizing:border-box;}
ul{margin-top:0;margin-bottom:1em;}
a{color:#1890ff;text-decoration:none;background-color:transparent;outline:none;cursor:pointer;transition:color .3s;-webkit-text-decoration-skip:objects;}
a:hover{color:#40a9ff;}
a:active{color:#096dd9;}
a:active,a:focus,a:hover{text-decoration:none;outline:0;}
a[disabled]{color:rgba(0,0,0,.25);cursor:not-allowed;}
a,input:not([type=range]){touch-action:manipulation;}
table{border-collapse:collapse;}
input{margin:0;color:inherit;font-size:inherit;font-family:inherit;line-height:inherit;}
input{overflow:visible;}
[type=search]{outline-offset:-2px;-webkit-appearance:none;}
::-moz-selection{color:#fff;background:#1890ff;}
::selection{color:#fff;background:#1890ff;}
.anticon{display:inline-block;color:inherit;font-style:normal;line-height:0;text-align:center;text-transform:none;vertical-align:-.125em;text-rendering:optimizelegibility;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;}
.anticon>*{line-height:1;}
.anticon svg{display:inline-block;}
.anticon:before{display:none;}
.ant-select-single .ant-select-selector{display:flex;}
.ant-select-single .ant-select-selector .ant-select-selection-search{position:absolute;top:0;right:11px;bottom:0;left:11px;}
.ant-select-single .ant-select-selector .ant-select-selection-search-input{width:100%;}
.ant-select-single .ant-select-selector .ant-select-selection-item{padding:0;line-height:30px;transition:all .3s;}
.ant-select-single .ant-select-selector .ant-select-selection-item{position:relative;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;}
.ant-select-single .ant-select-selector .ant-select-selection-item:after,.ant-select-single .ant-select-selector:after{display:inline-block;width:0;visibility:hidden;content:"\a0";}
.ant-select-single.ant-select-show-arrow .ant-select-selection-search{right:25px;}
.ant-select-single.ant-select-show-arrow .ant-select-selection-item{padding-right:18px;}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector{width:100%;height:32px;padding:0 11px;}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input{height:30px;}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector:after{line-height:30px;}
.ant-select{box-sizing:border-box;margin:0;padding:0;color:rgba(0,0,0,.85);font-size:14px;font-variant:tabular-nums;line-height:1.5715;list-style:none;font-feature-settings:"tnum","tnum";position:relative;display:inline-block;cursor:pointer;}
.ant-select:not(.ant-select-customize-input) .ant-select-selector{position:relative;background-color:#fff;border:1px solid #d9d9d9;border-radius:2px;transition:all .3s cubic-bezier(.645,.045,.355,1);}
.ant-select:not(.ant-select-customize-input) .ant-select-selector input{cursor:pointer;}
.ant-select:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input{margin:0;padding:0;background:transparent;border:none;outline:none;-webkit-appearance:none;-moz-appearance:none;appearance:none;}
.ant-select:not(.ant-select-disabled):hover .ant-select-selector{border-color:#40a9ff;border-right-width:1px;}
.ant-select-selection-item{flex:1 1;overflow:hidden;font-weight:400;white-space:nowrap;text-overflow:ellipsis;}
@media (-ms-high-contrast:none){
.ant-select-selection-item{flex:auto;}
}
.ant-select-arrow{display:inline-block;color:inherit;font-style:normal;line-height:0;text-transform:none;vertical-align:-.125em;text-rendering:optimizelegibility;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;position:absolute;top:50%;right:11px;display:flex;align-items:center;height:12px;margin-top:-6px;color:rgba(0,0,0,.25);font-size:12px;line-height:1;text-align:center;pointer-events:none;}
.ant-select-arrow>*{line-height:1;}
.ant-select-arrow svg{display:inline-block;}
.ant-select-arrow:before{display:none;}
.ant-select-arrow .anticon{vertical-align:top;transition:transform .3s;}
.ant-select-arrow .anticon>svg{vertical-align:top;}
.ant-spin-nested-loading{position:relative;}
.ant-spin-container{position:relative;transition:opacity .3s;}
.ant-spin-container:after{position:absolute;top:0;right:0;bottom:0;left:0;z-index:10;display:none\9;width:100%;height:100%;background:#fff;opacity:0;transition:all .3s;content:"";pointer-events:none;}
.ant-pagination{box-sizing:border-box;color:rgba(0,0,0,.85);font-size:14px;font-variant:tabular-nums;line-height:1.5715;font-feature-settings:"tnum","tnum";}
.ant-pagination{margin:0;padding:0;list-style:none;}
.ant-pagination:after{display:block;clear:both;height:0;overflow:hidden;visibility:hidden;content:" ";}
.ant-pagination-item,.ant-pagination-total-text{display:inline-block;height:32px;margin-right:8px;line-height:30px;vertical-align:middle;}
.ant-pagination-item{min-width:32px;font-family:-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji;text-align:center;list-style:none;background-color:#fff;border:1px solid #d9d9d9;border-radius:2px;outline:0;cursor:pointer;-webkit-user-select:none;-moz-user-select:none;-ms-user-select:none;user-select:none;}
.ant-pagination-item a{display:block;padding:0 6px;color:rgba(0,0,0,.85);transition:none;}
.ant-pagination-item a:hover{text-decoration:none;}
.ant-pagination-item:hover{border-color:#1890ff;transition:all .3s;}
.ant-pagination-item:hover a{color:#1890ff;}
.ant-pagination-item-active{font-weight:500;background:#fff;border-color:#1890ff;}
.ant-pagination-item-active a{color:#1890ff;}
.ant-pagination-item-active:hover{border-color:#40a9ff;}
.ant-pagination-item-active:hover a{color:#40a9ff;}
.ant-pagination-jump-next{outline:0;}
.ant-pagination-jump-next .ant-pagination-item-container{position:relative;}
.ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon{color:#1890ff;font-size:12px;letter-spacing:-1px;opacity:0;transition:all .2s;}
.ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-ellipsis{position:absolute;top:0;right:0;bottom:0;left:0;display:block;margin:auto;color:rgba(0,0,0,.25);font-family:Arial,Helvetica,sans-serif;letter-spacing:2px;text-align:center;text-indent:.13em;opacity:1;transition:all .2s;}
.ant-pagination-jump-next:hover .ant-pagination-item-link-icon{opacity:1;}
.ant-pagination-jump-next:hover .ant-pagination-item-ellipsis{opacity:0;}
.ant-pagination-jump-next,.ant-pagination-prev{margin-right:8px;}
.ant-pagination-jump-next,.ant-pagination-next,.ant-pagination-prev{display:inline-block;min-width:32px;height:32px;color:rgba(0,0,0,.85);font-family:-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji;line-height:32px;text-align:center;vertical-align:middle;list-style:none;border-radius:2px;cursor:pointer;transition:all .3s;}
.ant-pagination-next,.ant-pagination-prev{font-family:Arial,Helvetica,sans-serif;outline:0;}
.ant-pagination-disabled,.ant-pagination-disabled:hover{cursor:not-allowed;}
.ant-pagination-options{display:inline-block;margin-left:16px;vertical-align:middle;}
@media (-ms-high-contrast:none){
.ant-pagination-options{vertical-align:top;}
}
.ant-pagination-options-size-changer.ant-select{display:inline-block;width:auto;}
@media only screen and (max-width:992px){
.ant-pagination-item-before-jump-next{display:none;}
}
@media only screen and (max-width:576px){
.ant-pagination-options{display:none;}
}
.ant-table.ant-table-bordered>.ant-table-container{border-left:1px solid #f0f0f0;}
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>tbody>tr>td,.ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>thead>tr>th, .ant-table-content>table>thead>tr>td>span{border-right:1px solid #f0f0f0; text-align: center;}
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>thead>tr>th:before{background-color:transparent!important;}
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table{border-top:1px solid #f0f0f0;}
.ant-table-wrapper{clear:both;max-width:100%; margin-top: 10px;}
.ant-table-wrapper:before{display:table;content:"";}
.ant-table-wrapper:after{display:table;clear:both;content:"";}
.ant-table{box-sizing:border-box;margin:0;padding:0;color:rgba(0,0,0,.85);font-variant:tabular-nums;line-height:1.5715;list-style:none;font-feature-settings:"tnum","tnum";position:relative;font-size:14px;background:#fff;border-radius:2px;}
.ant-table table{width:100%;text-align:left;border-radius:2px 2px 0 0;border-collapse:separate;border-spacing:0;}
.ant-table-tbody>tr>td,.ant-table-thead>tr>th{position:relative;padding:16px;overflow-wrap:break-word;}
.ant-table-thead>tr>th{position:relative;color:rgba(0,0,0,.85);font-weight:500;text-align:left;background:#fafafa;border-bottom:1px solid #f0f0f0;transition:background .3s ease;}
.ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan]):before{position:absolute;top:50%;right:0;width:1px;height:1.6em;background-color:rgba(0,0,0,.06);transform:translateY(-50%);transition:background-color .3s;content:"";}
.ant-table-tbody>tr>td{border-bottom:1px solid #f0f0f0;transition:background .3s;}
.ant-table-tbody>tr.ant-table-row:hover>td{background:#fafafa;}
.ant-table-pagination.ant-pagination{margin:16px 0;}
.ant-table-pagination{display:flex;flex-wrap:wrap;row-gap:8px;}
.ant-table-pagination>*{flex:none;}
.ant-table-pagination-right{justify-content:flex-end;}
.ant-table-thead th.ant-table-column-has-sorters{outline:none;cursor:pointer;transition:all .3s;}
.ant-table-thead th.ant-table-column-has-sorters:hover{background:rgba(0,0,0,.04);}
.ant-table-thead th.ant-table-column-has-sorters:hover:before{background-color:transparent!important;}
.ant-table-column-title{position:relative;z-index:1;flex:1 1;}
.ant-table-column-sorters{display:flex;flex:auto;align-items:center;justify-content:space-between;}
.ant-table-column-sorters:after{position:absolute;top:0;right:0;bottom:0;left:0;width:100%;height:100%;content:"";}
.ant-table-column-sorter{margin-left:4px;color:#bfbfbf;font-size:0;transition:color .3s;}
.ant-table-column-sorter-inner{display:inline-flex;flex-direction:column;align-items:center;}
.ant-table-column-sorter-down,.ant-table-column-sorter-up{font-size:11px;}
.ant-table-column-sorter-up+.ant-table-column-sorter-down{margin-top:-.3em;}
.ant-table-column-sorters:hover .ant-table-column-sorter{color:#a6a6a6;}
.ant-table .ant-table-container:after,.ant-table .ant-table-container:before{position:absolute;top:0;bottom:0;z-index:1;width:30px;transition:box-shadow .3s;content:"";pointer-events:none;}
.ant-table .ant-table-container:before{left:0;}
.ant-table .ant-table-container:after{right:0;}
.ant-table-container{border-top-right-radius:2px;}
.ant-table-container,.ant-table-container table>thead>tr:first-child th:first-child{border-top-left-radius:2px;}
.ant-table-container table>thead>tr:first-child th:last-child{border-top-right-radius:2px;}
.ant-table-tbody>tr>td{padding: 0.75rem 0.75rem !important;}
.ant-table-thead>tr>th{border-bottom:1px solid #c1c1c1;border-top:1px solid #c1c1c1;font-weight:600;}
.ant-pagination-options{position:relative;z-index:-1;}
.ant-table-thead>tr>th{padding:8px 4px;}
.ant-table-tbody>tr>td{padding:6px 16px;}
.ant-table-thead>tr>th{color: #495057;
  background-color: #e9ecef;
  border-color: #e3eaef;}
a:active,a:focus,a:hover{text-decoration:none;color:#009ce7;outline:none;}
a{color:#000048;}
a,input{transition:all .4s ease;-moz-transition:all .4s ease;-o-transition:all .4s ease;-ms-transition:all .4s ease;-webkit-transition:all .4s ease;}
input{outline:none;}
@media only screen and (max-width:767.98px){
.table-responsive{display:block;width:100%;overflow-x:auto;-ms-overflow-style:-ms-autohiding-scrollbar;}
}
.red{color:red;}
.ant-table-pagination.ant-pagination{margin:16px 0 0;
  // position:fixed;bottom:0;right:32px;
  background-color:#fff;padding:10px;width:100%;z-index:1;}
.ant-table table{margin-bottom:10px;}
.fa{display:inline-block;font:normal normal normal 14px/1 FontAwesome;font-size:inherit;text-rendering:auto;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;}
.fa{-moz-osx-font-smoothing:grayscale;-webkit-font-smoothing:antialiased;display:inline-block;font-style:normal;font-feature-settings:normal;font-variant:normal;text-rendering:auto;line-height:1;}
.fa-eye:before{content:"\f06e";}
path{pointer-events:all;}
path:hover{opacity:.5;cursor:pointer;}
.ant-table-cell>span .fa.fa-solid.fa-eye.red:hover{cursor:pointer;}
/*! CSS Used fontfaces */
@font-face{font-family:FontAwesome;src:url(https://dct-sponsor-provider-journey.cloud.pcftest.com/_next/static/media/fontawesome-webfont.2b13baa7.eot);src:url(https://dct-sponsor-provider-journey.cloud.pcftest.com/_next/static/media/fontawesome-webfont.2b13baa7.eot#iefix&v=4.7.0) format("embedded-opentype"),url(https://dct-sponsor-provider-journey.cloud.pcftest.com/_next/static/media/fontawesome-webfont.e9955780.woff2) format("woff2"),url(https://dct-sponsor-provider-journey.cloud.pcftest.com/_next/static/media/fontawesome-webfont.cf011583.woff) format("woff"),url(https://dct-sponsor-provider-journey.cloud.pcftest.com/_next/static/media/fontawesome-webfont.8a7cb27d.ttf) format("truetype"),url(https://dct-sponsor-provider-journey.cloud.pcftest.com/_next/static/media/fontawesome-webfont.da909aa0.svg#fontawesomeregular) format("svg");font-weight:400;font-style:normal;}

/*medication css start*/
// .medication .ant-tag-red {
//   color: #cf1322;
//   background: none;
//   border-color: transparent;
//   border: 0px;
// }
// .medication .ant-tag-green {
//   color: #389e0d;
//   background: none;
//   border-color: transparent;
//   border: 0px;
// }
.medication .ant-tag{display: block; margin-bottom: 2px;}
/*medication css start End*/